import React from "react";
import { Avatar, Tooltip } from "antd";
import { FontSizes } from "Utils/enums";
import { S3_URL } from "config/constants";
import { colors } from "Assets/Styles/colors";
import { User } from "Utils/types";
import { getUserInitials } from "Utils/helpers";

type Props = {
  users: User[];
};

const UserHeadList = (props: Props) => {
  const { users } = props;

  return (
    <Avatar.Group
      maxCount={2}
      size="large"
      maxPopoverTrigger="click"
      maxStyle={{
        color: colors.secondaryText,
        fontSize: `${FontSizes.input}rem`,
        backgroundColor: colors.grey5,
        cursor: "pointer",
      }}
    >
      {users.map((user) => (
        <Tooltip key={user._id} title={`${user.firstName} ${user.lastName}`}>
          <Avatar
            src={user.profilePic ? `${S3_URL}${user.profilePic}` : null}
            style={{
              backgroundColor: colors.grey1,
              fontSize: `${FontSizes.input}rem`,
            }}
          >
            {!user.profilePic && getUserInitials(user.firstName, user.lastName)}
          </Avatar>
        </Tooltip>
      ))}
    </Avatar.Group>
  );
};

export default UserHeadList;
