import React from "react";
import { Props } from "./types";
import { TableWrapper } from "./styles";
import { Table as AntdTable, Empty } from "antd";
import { Box } from "@rebass/grid";

type TableProps = Props & React.ComponentProps<typeof AntdTable>;

const Table: React.FC<TableProps> = (props: TableProps) => {
  const {
    loading,
    columns,
    dataSource,
    onPageChange,
    totalCount,
    currentPage,
    noPagination,
    locale,
    onTableChange,
    scroll,
    ...rest
  } = props;

  const handlePageChange = (_page: number, _pageSize: number) => {
    onPageChange?.(_page);
  };

  const paginationConfig = {
    pageSize: 10,
    total: totalCount,
    current: currentPage,
    onChange: (_page: number, _pageSize: number) =>
      handlePageChange(_page, _pageSize),
  };

  return (
    <TableWrapper
      rowKey="_id"
      pagination={noPagination ? false : paginationConfig}
      loading={loading}
      dataSource={dataSource}
      columns={columns as any}
      showSorterTooltip={false}
      onChange={onTableChange}
      scroll={scroll}
      locale={{
        emptyText: loading ? (
          <Box padding="50px"></Box>
        ) : (
          <Box padding={20}>
            <Empty description={locale || "No Data"} />
          </Box>
        ),
      }}
      {...rest}
    />
  );
};

export default Table;
