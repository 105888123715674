import React from "react";
import { EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";

// Components
import Button from "Components/Button";
import UserHead from "Components/UIBlocks/userHead";

// Others
import { formatDate } from "Utils/helpers";
import { Box, Flex } from "@rebass/grid";
import { colors } from "Assets/Styles/colors";
import UserHeadList from "Components/UIBlocks/UserHeadList";
import { User } from "Utils/types";

export const formFields = [
  {
    key: "firstName",
    label: "First Name",
    type: "TF",
  },
  {
    key: "lastName",
    label: "Last Name",
    type: "TF",
  },
  {
    key: "dob",
    label: "Date of Birth",
    type: "DS",
  },
  {
    key: "ssn",
    label: "SSN ",
    type: "TF",
  },
  {
    key: "address",
    label: "Address ",
    type: "TF",
  },
  {
    key: "collaboratorId",
    label: "Collaborator",
    type: "ORG_USER",
  },
  {
    key: "showMyPatients",
    label: "Show only my patients ",
    type: "CB",
  },
];

export const getColumns = (
  handleEditPatient: any,
  handleDelete: any,
  organizationId: string,
  navigate: any,
  canEditCase: boolean,
) => [
  {
    title: "Patient Details",
    dataIndex: "name",
    key: "name",
    show: true,
    render: (_: string, record: any) => (
      <Box
        onClick={() => navigate(`/patient/${record?._id}/details`)}
        style={{ cursor: "pointer" }}
      >
        <UserHead
          isPointer
          followUpCount={record.followUpCount}
          url={record?.profilePicKey}
          label={`${record.firstName} ${record.lastName}`}
        />
      </Box>
    ),
  },
  {
    title: "Collaborator(s)",
    dataIndex: "visibleToUsers",
    key: "visibleToUsers",
    render: (visibleToUsers: User[] = []) => (
      visibleToUsers.length ? <UserHeadList users={visibleToUsers} /> : "-"
    ),
  },
  {
    title: "Date of Birth",
    width: 150,
    dataIndex: "dob",
    key: "dob",
    render: (text: string) => formatDate(text),
  },
  {
    title: "SSN",
    width: 150,
    dataIndex: "ssn",
    key: "ssn",
  },
  {
    title: "Address",
    dataIndex: "addressLine1",
    key: "addressLine1",
    render: (addressLine1: string) => addressLine1 || "-",
  },
  {
    title: "Actions",
    dataIndex: "_id",
    key: "_id",
    width: 150,
    render: (_id: string, record: any) => {
      const canEdit = canEditCase && record?.visibleInOrganizations?.includes(organizationId);
      return (
        <Flex style={{ gap: "6px" }}>
          <Button
            type="dashed"
            onClickHandler={() => navigate(`/patient/${_id}/details`)}
            icon={<EyeOutlined />}
            size="small"
          />
          {canEdit && (
            <>
              <Button
                type="dashed"
                onClickHandler={handleEditPatient(_id)}
                icon={<EditOutlined />}
                size="small"
              />
              <Button
                type="dashed"
                onClickHandler={handleDelete(_id)}
                icon={<DeleteOutlined style={{ color: colors.error }} />}
                size="small"
              />
            </>
          )}
        </Flex>
      );
    },
  },
];
