import React, { useReducer, useCallback, useMemo, useState } from "react";
import { Box, Flex } from "@rebass/grid";
import { Grid, Label } from "Assets/Styles/globalStyles";
import { Collapse, DatePicker, Switch } from "antd";
import {
  SearchOutlined,
  FilterOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import Button from "Components/Button";
import InputField from "Components/InputField";
import Select from "Components/Select";
import { FontSizes, FontWeight } from "Utils/enums";
import { formatSSN, getFilteredObject } from "Utils/helpers";
import { FilterWrapper, HeaderWrapper } from "./styles";
import dayjs from "dayjs";
import { FieldType, Props } from "./types";
import { colors } from "Assets/Styles/colors";
import { DATE_FORMAT } from "config/constants";
import OrgUserSelect from "Components/UIBlocks/OrgUserSelect";
import { Tooltip } from "antd/lib";

const { Panel } = Collapse;

const formReducer = (state: any, action: any) => {
  switch (action.type) {
    case "SET_FIELD":
      return { ...state, [action.key]: action.value };
    case "RESET":
      return {};
    default:
      return state;
  }
};

const PageHeader: React.FC<Props> = ({
  formFields,
  title,
  addNewBtnLabel,
  handleAdd,
  fetchData,
  loading,
  noFilter,
  hideAdd,
  disableAddTooltip = "",
  disableAdd,
  hideTitle,
  singleRow,
}) => {
  const [formData, dispatch] = useReducer(formReducer, {});
  const [filterOpen, setFilterOpen] = useState(false);

  const handleFieldChange = useCallback((key: string, value: any) => {
    dispatch({
      type: "SET_FIELD",
      key,
      value: key === "ssn" ? formatSSN(value) : value,
    });
  }, []);

  const handleDateChange = useCallback(
    (key: string, date: any, dateString: any) => {
      dispatch({ type: "SET_FIELD", key, value: dateString });
    },
    []
  );

  const handleSwitchChange = useCallback((key: string, checked: boolean) => {
    dispatch({ type: "SET_FIELD", key, value: checked });
  }, []);

  const handleToggleFilter = useCallback(
    () => setFilterOpen((prev) => !prev),
    []
  );

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      fetchData(getFilteredObject(formData));
    },
    [formData, fetchData]
  );

  const handleClearForm = useCallback(() => {
    dispatch({ type: "RESET" });
  }, []);

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLFormElement>) => {
      if (event.key === "Enter") {
        event.preventDefault?.();
        handleSubmit(event);
      }
    },
    [handleSubmit]
  );

  const valuesCount = useMemo(
    () =>
      Object.keys(formData).filter(
        (key) => key !== "searchQuery" && formData[key]
      ).length,
    [formData]
  );

  return (
    <form onSubmit={handleSubmit} onKeyPress={handleKeyPress}>
      {!hideTitle && (
        <Label fontWeight={FontWeight.medium} fontSize={FontSizes.h3}>
          {title}
        </Label>
      )}
      <Flex marginTop={15} justifyContent="space-between" alignItems="center">
        <HeaderWrapper>
          <InputField
            noLabel
            placeholder="Search"
            onChange={(value) => handleFieldChange("searchQuery", value)}
            value={formData.searchQuery}
            label=""
            isMandatory
          />
          <Button
            disabled={loading}
            icon={<SearchOutlined />}
            onClickHandler={handleSubmit}
          />
          {!noFilter && (
            <Button
              icon={<FilterOutlined />}
              onClickHandler={handleToggleFilter}
              type="dashed"
              tagCount={!filterOpen && valuesCount}
              label={filterOpen ? "Hide Filter" : "Filter"}
            />
          )}
        </HeaderWrapper>
        {!hideAdd && (
          <Tooltip title={disableAddTooltip} overlayInnerStyle={{ width: '210px' }} >
            <Box>
              <Button
                icon={<PlusCircleOutlined />}
                onClickHandler={handleAdd}
                disabled={disableAdd}
                label={addNewBtnLabel}
                toolTip="Add New"
              />
            </Box>
          </Tooltip>
        )}
      </Flex>
      <Collapse
        className="no-panel-collapse"
        accordion
        activeKey={filterOpen ? ["1"] : []}
      >
        <Panel header="Panel 1" key="1">
          <FilterWrapper>
            <Grid col={4} gap={12}>
              {formFields?.map(({ type, key, label, options }: any) => (
                <>
                  {
                    {
                      [FieldType.TF]: (
                        <InputField
                          key={key}
                          placeholder="Enter"
                          onChange={(value) => handleFieldChange(key, value)}
                          value={formData[key]}
                          label={label}
                        />
                      ),
                      [FieldType.CB]: (
                        <Flex key={key} alignItems="center" marginTop={20}>
                          <Switch
                            checked={formData[key]}
                            onChange={(checked) =>
                              handleSwitchChange(key, checked)
                            }
                          />
                          <Label
                            marginLeft={5}
                            fontSize={FontSizes.floatingLabel}
                            color={colors.primaryText}
                            fontWeight={FontWeight.default}
                          >
                            {label}
                          </Label>
                        </Flex>
                      ),
                      [FieldType.SB]: (
                        <Select
                          key={key}
                          label={label}
                          placeholder="Enter"
                          onChangeHandler={(value: any) =>
                            handleFieldChange(key, value)
                          }
                          options={options}
                          value={formData[key]}
                        />
                      ),
                      [FieldType.ORG_USER]: (
                        <Box>
                          <Label
                            marginBottom={5}
                            fontSize={FontSizes.floatingLabel}
                            color={colors.primaryText}
                            fontWeight={FontWeight.default}
                          >
                            {label}
                          </Label>
                          <OrgUserSelect
                            onUserSelect={(user) => {
                              handleFieldChange(key, user?._id);
                            }}
                          />
                        </Box>
                      ),
                      [FieldType.DS]: (
                        <Box key={key}>
                          <Label
                            marginBottom={5}
                            fontSize={FontSizes.floatingLabel}
                            color={colors.primaryText}
                            fontWeight={FontWeight.default}
                          >
                            {label}
                          </Label>
                          <DatePicker
                            format={DATE_FORMAT}
                            value={formData[key] ? dayjs(formData[key]) : null}
                            onChange={(date, dateString) =>
                              handleDateChange(key, date, dateString)
                            }
                          />
                        </Box>
                      ),
                    }[type]
                  }
                </>
              ))}
              {!singleRow && <Box />}
              <Flex
                style={{ gap: "8px" }}
                alignItems="end"
                justifyContent="end"
              >
                <Button
                  label="Reset"
                  type="dashed"
                  onClickHandler={handleClearForm}
                />
                <Button
                  disabled={loading}
                  htmlType="submit"
                  onClickHandler={handleSubmit}
                  label={"Submit"}
                />
              </Flex>
            </Grid>
          </FilterWrapper>
        </Panel>
      </Collapse>
    </form>
  );
};

export default PageHeader;
