import { GlobalState, UserDetailsState } from "./types";

export const userInitialState: UserDetailsState = {
    _id: "",
    isAdmin: false,
    origin: undefined,
    isStaff: false,
    isUnlimitedPlan: false,
    isPaidPlan: false,
    userDetailsCount: {
        followupCount: undefined,
    },
    currentSubscription: undefined,
    currentOrg: {
        isDefault: false,
        organizationId: "",
        roles: []
    },
    isOrgOwner: false,
    personalInformation: {
        country: "US",
        dob: "",
        firstName: "",
        lastName: "",
        medicalLicenseNumber: "",
        phoneNumber: "",
        profilePicKey: "",
    },
    userId: "",
    organizationId: "",
    isInvitedUser: false,
    email: "",
    organizationName: "",
    status: undefined
};

export const globalInitialState: GlobalState = {
    planUpdating: false,
    aiImageProcessing: false
};
