/* eslint-disable react-hooks/exhaustive-deps */
// Core
import React, { useEffect, useRef, useState } from "react";
import { SendOutlined, WarningOutlined } from "@ant-design/icons";

// Styles
import { Box, Flex } from "@rebass/grid";
import { colors } from "Assets/Styles/colors";

// Components
import { Grid, Label } from "Assets/Styles/globalStyles";
import PageHeader from "Components/PageHeader";
import Table from "Components/Table";

// Types
import { FontSizes, FontWeight } from "Utils/enums";

// Others
import {
  formFields,
  getColumns,
  getCurrentOrgRoles,
  getFilteredData,
  officeOptions,
} from "./helpers";
import { fetchData } from "Utils/fetch";
import { restAPIs } from "Utils/restAPIs";
import Modal from "antd/es/modal/Modal";
import Button from "Components/Button";
import InputField from "Components/InputField";
import { pluralize, validateEmail } from "Utils/helpers";
import Checkbox from "antd/es/checkbox";
import { useUserDetails } from "hooks/useUserDetails";
import { useDebounce } from "hooks/useDebounceSearch";
import { PlanWrapper, StatusTag } from "./styles";
import { useNavigate } from "react-router-dom";
import { useOrganization } from "hooks/useOrganization";

const MyOffice = () => {
  const { userLicenseRemaining, canManageBilling } = useUserDetails();
  const { updateOrganizationData } = useOrganization();
  const navigate = useNavigate();
  const STATE = {
    email: "",
    roles: "Doctor",
  };
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [data, setData] = useState<any>([]);
  const [formData, setFormData] = useState<any>(STATE);
  const dataRef = useRef<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [userLoading, setUserLoading] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const [userList, setUserList] = useState<any[]>([]);
  const { email, roles, isEdit } = formData;

  const context = "Member";

  const handleCheckboxChange = (newRole: string) => () => {
    setFormData({
      ...formData,
      roles: newRole,
    });
  };

  const { organizationId, userDetails, canEditOfficeMember } = useUserDetails();
  const debouncedEmail = useDebounce(email, 500);

  const handleEmailCheck = async () => {
    setUserLoading(true);
    const res = await fetchData(
      restAPIs.getOrgUsers({
        page: 1,
        pageSize: 20,
        searchQuery: debouncedEmail,
        currentOrganizationId: organizationId,
      })
    );
    if (res.data) {
      const filteredOptions = res.data?.results.map((item: any) => {
        return {
          label: `${item?.personalInformation.firstName} ${item?.personalInformation.lastName}`,
          value: item.email,
          url: item.personalInformation?.profilePicKey,
        };
      });
      setUserList(filteredOptions);
    }
    setUserLoading(false);
  };

  useEffect(() => {
    if (debouncedEmail) handleEmailCheck();
  }, [debouncedEmail]);

  const validateForm = (): boolean => {
    const newError = {
      email: validateEmail(email) ? "" : "Please enter a valid email address",
      roles: roles.length ? "" : "Please select any role",
    };
    setErrors(newError);
    return !Object.values(newError).some((item) => !!item);
  };

  const handleFilterChange = (formData: any) => {
    setData(getFilteredData(dataRef.current, formData));
  };

  const fetchTableData = async () => {
    setLoading(true);
    const inviteReq = restAPIs.getInviteList(organizationId);
    const userReq = restAPIs.getUserList(organizationId);
    const [inviteRes, userRes] = await Promise.all([
      fetchData(inviteReq),
      fetchData(userReq),
    ]);
    const data = [...inviteRes?.data, ...userRes?.data];
    const _data = data
      .slice()
      .sort((a, b) => a.createdAt?.localeCompare(b?.createdAt));
    setData(_data);
    dataRef.current = _data;
    setLoading(false);
  };

  useEffect(() => {
    fetchTableData();
  }, []);

  const handleDelete = (item: any) => async () => {
    let res = {};
    if (item?.status === "Invited") {
      res = await fetchData(restAPIs.deleteInvite(item?._id, organizationId));
    } else {
      res = await fetchData(restAPIs.deleteTeam(item?._id, organizationId));
      await updateOrganizationData();
    }
    if (res) {
      fetchTableData();
    }
  };

  const handleAddOrEditMember = (_data?: any) => () => {
    const itemEmail = _data?.email || _data?.invitedEmail || "";
    const currentOrgRoles = getCurrentOrgRoles(_data, organizationId);
    const roleItem = _data?.roles || currentOrgRoles;
    setFormData({
      ..._data,
      isEdit: !!_data?._id,
      email: itemEmail?.trim(),
      roles: roleItem?.[0] || "Doctor",
    });
    setOpen((prev) => !prev);
  };

  const handleOk = async () => {
    if (!validateForm()) return null;
    setApiLoading(true);
    const isEdit = formData?.isEdit;
    const payload = {
      email: formData?.email?.trim(),
      roles: [formData?.roles],
    };
    let res: any = {};
    if (isEdit) {
      res = await fetchData(
        restAPIs.updateUserRoles(
          { roles: payload?.roles },
          formData?._id,
          organizationId
        )
      );
      if (res) {
        updateOrganizationData();
      }
    } else {
      res = await fetchData(restAPIs.inviteUser(payload, organizationId));
    }
    if (res) {
      fetchTableData();
      handleCancel();
    }
    setApiLoading(false);
  };

  const handleCancel = () => {
    setFormData(STATE);
    setErrors({});
    setUserList([]);
    setOpen(false);
  };

  const onchangeHandler = (key: string) => (value: string) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const tableCols = getColumns(
    handleAddOrEditMember,
    handleDelete,
    userDetails?._id,
    canEditOfficeMember,
    organizationId,
    context
  );

  const hideAddAction = !canEditOfficeMember;

  if (hideAddAction) {
    tableCols.pop();
  }

  const planStatus = userLicenseRemaining === 0 ? "warning" : "success";
  const isDoctor = roles?.includes("Doctor");

  return (
    <>
      <PageHeader
        title="My Office"
        loading={loading}
        addNewBtnLabel={`Add New ${context}`}
        handleAdd={handleAddOrEditMember()}
        formFields={formFields}
        fetchData={handleFilterChange}
        noFilter
        hideAdd={hideAddAction}
      />
      <Flex marginTop={20} marginBottom={15}>
        <Label fontWeight={FontWeight.medium} fontSize={FontSizes.h6}>
          {data?.length || 0}
        </Label>
        <Label
          marginLeft={5}
          fontWeight={FontWeight.medium}
          color={colors.grey1}
          fontSize={FontSizes.h6}
        >
          {pluralize(data?.length, context)}
        </Label>
      </Flex>
      <Box>
        <Table
          noPagination
          loading={loading}
          dataSource={data}
          columns={tableCols as any}
        />
      </Box>
      {open && (
        <Modal
          title={`${isEdit ? "Edit" : "Add New"} ${context}`}
          width={700}
          open={open}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button
              type="dashed"
              label="Cancel"
              onClickHandler={handleCancel}
            />,
            <Button
              loading={apiLoading}
              disabled={!userLicenseRemaining && isDoctor}
              label={`${isEdit ? "Update" : "Send"} Invite`}
              icon={<SendOutlined />}
              onClickHandler={handleOk}
            />,
          ]}
        >
          <InputField
            onChange={onchangeHandler("email")}
            placeholder="Email"
            value={email}
            disabled={isEdit}
            label={`Enter team ${context} email address`}
            error={errors.email}
            isMandatory
            isAutoComplete
            options={userList}
            optionLoading={userLoading}
          />
          <Label marginTop={20} marginBottom={10} fontSize={FontSizes.h4}>
            Select Team roles
          </Label>
          <PlanWrapper status={planStatus}>
            <Box style={{ maxWidth: "70%" }}>
              <StatusTag status={planStatus}>
                {!userLicenseRemaining ? (
                  <>
                    <WarningOutlined style={{ color: colors.error4 }} /> No
                    pending licenses
                  </>
                ) : (
                  <>{userLicenseRemaining} {pluralize(userLicenseRemaining, "License")} left</>
                )}
              </StatusTag>
              <Label
                marginTop={15}
                marginBottom={15}
                fontSize={FontSizes.small}
              >
                {!userLicenseRemaining
                  ? `Your Workspace has been reaching its maximum number of
                  members allowed. Purchase more licenses and add a new
                  member.`
                  : `Great news! Your Workspace currently has available licenses to accommodate new members. Keep expanding your team and making the most of your resources!`}
              </Label>
              <Checkbox
                checked={roles === "Doctor"}
                onChange={handleCheckboxChange("Doctor")}
              >
                Doctor
              </Checkbox>
            </Box>
            {canManageBilling && !userLicenseRemaining && (
              <Button
                onClickHandler={() => navigate("/update-subscription-plan")}
                label="Buy Now"
              />
            )}
          </PlanWrapper>
          <Label
            marginTop={20}
            marginBottom={15}
            fontWeight={FontWeight.medium}
            fontSize={FontSizes.input}
          >
            Other Roles
          </Label>
          <Grid>
            {officeOptions.map((option) => (
              <Checkbox
                checked={roles === option.value}
                onChange={handleCheckboxChange(option.value)}
              >
                <Box>
                  <Label fontSize={FontSizes.input}>{option.label}</Label>
                  <Label
                    fontSize={FontSizes.table}
                    color={colors.secondaryText}
                  >
                    {option.additionalText}
                  </Label>
                </Box>
              </Checkbox>
            ))}
          </Grid>
        </Modal>
      )}
    </>
  );
};

export default MyOffice;
