export const colors = {
    white: "#FFFFFF",
    primary: '#0A58D5',
    primary2: '#2E74E3',
    blue3: '#91B6F0',
    blue2: '#C2D6F7',
    blue1: '#E6EEFC',
    blue5: '#F8FAFE',
    blue4: '#2E4264',
    blue6: '#F5F9FF',
    blue7: '#4B81CD',
    blue8: '#408EE2',
    darkPrimary: '#02317D',
    darkPrimary2: '#01193F',
    primaryText: '#222222',
    error: '#FF4D4F',
    error2: 'rgb(255 77 79 / 50%)',
    error3: '#FFEBEB',
    error4: '#DB2828',
    success: '#52C41A',
    green1: "#83D7BF",
    success2: 'rgb(135 208 104 / 50%)',
    success4: '#EAFFDF',
    orange: 'rgba(250, 187, 5, 0.28)',
    orange2: 'rgba(250, 187, 5, 0.50)',
    orange3: 'rgba(250, 187, 5, 1)',
    secondaryText: '#666666',
    grey1: '#888888',
    grey2: '#E1E1E1',
    grey3: '#F9F9F9',
    grey4: '#F1F1F1',
    grey5: '#d9d9d9',
    grey6: '#7C7C7C',
    grey7: '#aaaabc',
    grey8: '#FDFDFD',
    grey9: '#b2b2b2',
    grey10: '#f2f2f2',
    grey11: '#555555',
    border: '#F1F1F1',
    border2: '#E1E1E1',
    bridge: '#00C8E3',
    crown: '#008DF2',
    screw: '#83D7BF',
    pontic: "#16A085",
    implant: '#C4A862',
    extraction: '#FF4D4F',
    inprogress: '#F9881F',
    purple: '#ece0fe',
    temporaryTreatment: "url(#stripedPattern)",
    unread: "#fff1d7"
}

export function darkenHex(color: string, factor: number) {
    // Remove the '#' from the beginning
    color = color.replace(/^#/, '');

    // Convert HEX to RGB
    var r = parseInt(color.substring(0,2),16);
    var g = parseInt(color.substring(2,4),16);
    var b = parseInt(color.substring(4,6),16);

    // Darken each RGB component by the specified factor
    r = Math.round(r * factor);
    g = Math.round(g * factor);
    b = Math.round(b * factor);

    // Ensure the values are within the valid range (0-255)
    r = Math.min(Math.max(r, 0), 255);
    g = Math.min(Math.max(g, 0), 255);
    b = Math.min(Math.max(b, 0), 255);

    // Convert RGB to HEX
    var darkenedColor = "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);

    return darkenedColor;
}