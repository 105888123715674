// Core
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

// Styles
import { Box, Flex } from "@rebass/grid";
import { colors } from "Assets/Styles/colors";

// Components
import { Label } from "Assets/Styles/globalStyles";
import PageHeader from "Components/PageHeader";
import Table from "Components/Table";

// Types
import { FontSizes, FontWeight } from "Utils/enums";

// Others
import { formFields, getColumns } from "./helpers";
import { fetchData } from "Utils/fetch";
import { restAPIs } from "Utils/restAPIs";
import { pluralize } from "Utils/helpers";
import { useUserDetails } from "hooks/useUserDetails";
import Button from "Components/Button";

const Patients: React.FC = () => {
  const navigate = useNavigate();
  const { organizationId, canEditCase } = useUserDetails();
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [filterData, setFilterData] = useState({});

  const { canEditPatient, isAdmin } = useUserDetails();

  const handleFilterChange = (formData: any) => {
    setFilterData(formData);
  };

  const fetchTableData = async (pagination?: any) => {
    setLoading(true);
    const payload = {
      ...filterData,
      page: 1,
      includeFollowUpCount: true,
      pageSize: 10,
      ...pagination,
    };
    const res = await fetchData(restAPIs.getPatientList(payload));
    if (res?.data) {
      setData({
        totalCount: res?.data?.totalCount,
        currentPage: res?.data?.currentPage,
        results: res?.data?.results?.map((item: any) => ({
          ...item?.personalInformation,
          _id: item._id,
          visibleInOrganizations: item.visibleInOrganizations,
          visibleToUsers: item.visibleToUsers,
          createdAt: item.createdAt,
          followUpCount: item.followUpCount,
          invitedById: item.invitedById,
        })),
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTableData();
  }, [filterData]);

  const handleDelete = (id: string) => async () => {
    const res = await fetchData(restAPIs.deletePatient(id, organizationId));
    if (res) {
      fetchTableData();
    }
  };

  const handlePageChange = (page: number) => {
    fetchTableData({ page });
  };

  const handleAddOrEditPatient = (_id?: string) => () => {
    navigate(_id ? _id : "create");
  };

  const tableCols = getColumns(
    handleAddOrEditPatient,
    handleDelete,
    organizationId,
    navigate,
    canEditCase
  );

  return (
    <>
      <PageHeader
        title="Patients"
        loading={loading}
        addNewBtnLabel="Add New Patient"
        handleAdd={handleAddOrEditPatient()}
        formFields={formFields}
        singleRow
        fetchData={handleFilterChange}
        hideAdd={isAdmin}
        disableAdd={!canEditPatient}
        disableAddTooltip={
          canEditPatient ? (
            ""
          ) : (
            <Box padding="5px">
              <Label marginBottom={10} fontSize={FontSizes.input}>
                You need to change your subscription in order to add additional
                patients.
              </Label>
              <Button
                onClickHandler={() => navigate("/update-subscription-plan")}
                label="Upgrade Subscription Plan"
              />
            </Box>
          )
        }
      />
      <Flex marginTop={20} marginBottom={15}>
        <Label fontWeight={FontWeight.medium} fontSize={FontSizes.h6}>
          {data?.totalCount || 0}
        </Label>
        <Label
          marginLeft={5}
          fontWeight={FontWeight.medium}
          color={colors.grey1}
          fontSize={FontSizes.h6}
        >
          {pluralize(data?.totalCount, "Patient")}
        </Label>
      </Flex>
      <Box>
        <Table
          totalCount={data?.totalCount}
          currentPage={data?.currentPage}
          onPageChange={handlePageChange}
          loading={loading}
          dataSource={data?.results}
          columns={tableCols as any}
          locale="No Patients"
        />
      </Box>
    </>
  );
};

export default Patients;
