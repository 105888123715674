// Core
import React from "react";
import { NavLink, useLocation } from "react-router-dom";

// Style
import { MenuItem, MenuList, SideBarWrapper } from "./styles";
import { Props } from "./types";
import { Label } from "Assets/Styles/globalStyles";

// Types
import { FontSizes } from "Utils/enums";

// Components
import Logo from "Assets/Images/logo_white.svg";
import messageIcon from "Assets/Images/message.svg";

// Others
import { Box } from "@rebass/grid";
import { INITIAL_ROUTE } from "config/constants";
import { sideBarMenu } from "./helpers";
import {
  MessageIconWrapper,
  MessagesCount,
  StepCounter,
  StyledImage,
} from "Pages/CreateCase/TimeLine/styles";
import { useUserDetails } from "hooks/useUserDetails";

const SideBar: React.FC<Props> = () => {
  const location = useLocation();
  const { userDetailsCount } = useUserDetails();
  const currentPath = location.pathname;

  return (
    <SideBarWrapper>
      <Box>
        <NavLink to={INITIAL_ROUTE}>
          <img alt="logo" src={Logo} />
        </NavLink>
      </Box>
      <MenuList>
        {sideBarMenu.map(({ path, label, icon, activeFor }) => {
          const showFollowup = path.includes("/control-panel");
          return (
            <MenuItem
              className={`${
                currentPath.includes(activeFor as string) ? "active" : ""
              }`}
              to={path}
              end
              key={label}
            >
              {icon}
              <Label marginRight={8} pointer fontSize={FontSizes.sidebar}>
                {label}
              </Label>
              {showFollowup &&
                !!userDetailsCount?.followupCount?.totalFollowups && (
                  <StepCounter>
                    {userDetailsCount?.followupCount?.totalFollowups > 9
                      ? "9+"
                      : userDetailsCount?.followupCount?.totalFollowups}
                  </StepCounter>
                )}
              {showFollowup &&
                !!userDetailsCount?.followupCount?.totalUnreadFollowups && (
                  <MessageIconWrapper>
                    <StyledImage alt="messages" src={messageIcon} />
                    <MessagesCount>
                      {userDetailsCount?.followupCount?.totalUnreadFollowups > 9
                        ? "9+"
                        : userDetailsCount?.followupCount?.totalUnreadFollowups}
                    </MessagesCount>
                  </MessageIconWrapper>
                )}
            </MenuItem>
          );
        })}
      </MenuList>
    </SideBarWrapper>
  );
};

export default SideBar;
