import axios, { AxiosRequestConfig } from 'axios';
import { Auth } from 'aws-amplify';
import { Modal } from 'antd';
import { BASE_URL } from 'config/constants';
import { openNotificationWithIcon } from './helpers';
import { contentTypeMappings } from './enums';

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const { confirm } = Modal;

interface Request {
  method: string;
  endpoint: string;
  body?: any;
  withoutToken?: boolean;
  fullUrl?: boolean;
  context?: string;
  contentType?: string;
  successToast?: string;
  action?: string;
}

export const fetchData = async (request: Request): Promise<any> => {
  const { method, endpoint, body, withoutToken, context, successToast, action, fullUrl, contentType } = request;

  let tokenStr = '';

  let url = fullUrl ? endpoint : `${axiosInstance.defaults.baseURL}${endpoint}`;

  if (!withoutToken) {
    try {
      const session = await Auth.currentSession();
      tokenStr = session.getIdToken().getJwtToken();
    } catch (error) {
      await Auth.signOut();
      console.log(error)
    }
  }

  const config: AxiosRequestConfig = {
    method,
    url,
    data: body,
    headers: {},
  };

  if (tokenStr) {
    config.headers!.Authorization = `Bearer ${tokenStr}`; // Use non-null assertion operator '!' to tell TypeScript that headers is not null or undefined
  }

  // Add contentType to headers if provided
  if (contentType) {
    if (!config.headers) {
      config.headers = {}; // Initialize headers object if it's undefined
    }
    config.headers['Content-Type'] = contentTypeMappings[contentType] || 'application/octet-stream';
  }

  if ([action, method.toLowerCase()].includes('delete')) {
    // Show confirm popup for delete operation
    return new Promise(async (resolve, reject) => {
      confirm({
        title: 'Confirm Delete',
        className: 'confirm-modal',
        content: `Are you sure you want to delete this ${context}?`,
        onOk: async () => {
          try {
            const response = await axiosInstance(config);
            if(successToast) {
              openNotificationWithIcon(successToast, 'success', "Success")
            }
            resolve(response.data || true);
          } catch (error: any) {
            handleApiError(error, reject);
          }
        },
        onCancel: () => { },
      });
    });
  } else {
    // For other operations, proceed with the request
    try {
      const response = await axiosInstance(config);
      if(successToast) {
        openNotificationWithIcon(successToast, 'success', "Success")
      }
      return response.data || true;
    } catch (error: any) {
      handleApiError(error);
    }
  }
};


const handleApiError = (error: any, reject?: (reason?: any) => void) => {
  const status = error?.response?.status;
  if (status === 401) {
    Auth.signOut();
  }
  openNotificationWithIcon(`${error?.response?.data?.message || error?.message}`);
  if (reject) {
    console.log(error);
  } else {
    console.log(error);
  }
};
