import styled, { css } from "styled-components";
import { colors } from "Assets/Styles/colors";
import { ReactComponent as PlusFilledIcon } from "Assets/Images/plus-filled.svg"
import { ReactComponent as DirectionIcon } from "Assets/Images/direction.svg"
import { ReactComponent as Direction2Icon } from "Assets/Images/direction2.svg"
import { Label } from "Assets/Styles/globalStyles";
import { Status } from "./types";
import { FontSizes } from "Utils/enums";

interface SortableItemContainerIconWrapperProps {
  readonly?: boolean;
}
interface SortableItemContainerIconProps {
  status: Status;
}

const getStatusStyles = (status: string) => {
  switch (status) {
    case 'complete':
      return {
        borderColor: colors.blue8,
        fill: colors.grey7,
      };
    case 'inprogress':
      return {
        borderColor: colors.success,
        fill: colors.blue6,
      };
    case 'onhold':
      return {
        borderColor: colors.error,
        fill: colors.blue6,
      };
    default:
      return {
        borderColor: colors.blue1,
        fill: colors.blue6,
      };
  }
};

export const SortableItemContainerIcon1 = styled(DirectionIcon) <SortableItemContainerIconProps>`
  width: 100%;
  position: absolute;
  z-index: 0;
  cursor: pointer; 
  color: ${({ status }) => getStatusStyles(status).borderColor};

  path {
    fill: ${({ status }) => getStatusStyles(status).fill};
  }
`;

export const SortableItemContainerIcon2 = styled(Direction2Icon) <SortableItemContainerIconProps>`
  width: calc(100% + 3px);
  position: absolute;
  z-index: 0;
  cursor: pointer;
  padding: 5px 6px 5px 0;
  color: ${({ status }) => getStatusStyles(status).borderColor};

  path {
    fill: ${({ status }) => getStatusStyles(status).fill};
  }
`;

export const SortableItemContainerIconWrapper = styled.div`
  display: none;
  background-color: ${colors.blue6};
  position: absolute;
  top: 22px;
  padding: 2px 6px;
  margin: 0;
  border-radius: 4px;
  border: 1px solid ${colors.blue1};
  cursor: pointer;
`;

export const SortableItemContainer = styled.div<SortableItemContainerIconWrapperProps>`
  position: relative;

  &:hover ${SortableItemContainerIconWrapper} {
    display: flex;
  }
  svg {
    stroke-width: 2px;
  }
  ${(props) =>
    props.readonly &&
    css`
      pointer-events: none;
    `}
`;


export const SortableItemContainerText = styled.span`
  z-index: 10;
  font-weight: 600;
  font-size: 11px;
  width: 100px;
  text-align: center;
`;

export const CustomButton = styled.button`
  background-color: transparent;
  border: none;
  color: white;
  width: 110px;
  background-color: ${colors.success};
  cursor: pointer;
  font-size: ${FontSizes.table}rem;
  padding: 5px 0;
  border-radius: 5px;
`;

export const StepInfoBar = styled.div`
    height: 25px;
    width: calc(100% - 15px);
    position: absolute;
    left: 0;
    top: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StepCounter = styled.div`
    width: 22px;
    height: 22px;
    background-color: ${colors.error4};
    font-size: ${FontSizes.table}rem;
    border-radius: 15px;
    align-items: center;
    display: flex;
    justify-content: center;
    color: white;
    cursor: pointer;
`;
export const MessageIconWrapper = styled.div`
    margin-left: 8px;
    margin-bottom: -2px;
    align-items: center;
    display: flex;
    position: relative;
    justify-content: center;
    color: white;
    cursor: pointer;
`;
export const StyledImage = styled.img`
    margin: 0 !important;
    width: 23px;
`;
export const MessagesCount = styled.div`
    position: absolute;
    top: 0px;
    font-size: ${FontSizes.table}rem;
`;

export const PlusIcon = styled(PlusFilledIcon)`
  width: 16px;
  height: 16px;
  margin-left: 1px;
  color: ${colors.blue2};
  cursor: pointer;
  
  &:hover {
    color: ${colors.primary};
  }
`;

export const TimelineWrapper = styled.div`
  row-gap: 15px;
  display: flex;
  overflow-x: auto;
  padding: 20px 0 15px;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.grey7};
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${colors.grey6};
    cursor: pointer;
  }
`;

export const TimeLineLoader = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #ffffffba;
  z-index: 99;
`;