import { ActionTypes, OrganizationRoleEnum, PlanType } from "Utils/enums";
import { fetchData } from "Utils/fetch";
import { restAPIs } from "Utils/restAPIs";
import { useDispatch, useSelector } from "react-redux";
import { RootState, UserDetailsState } from "reducers/types";

export const useUserDetails = () => {
  const dispatch = useDispatch();
  const userDetailsState = useSelector<RootState, UserDetailsState>(
    (store) => store.userDetailsReducer
  );

  const { currentSubscription, currentOrg, userDetailsCount } =
    userDetailsState;

  const updateUserCounts = async () => {
    const res = await fetchData(restAPIs.getFollowupCount);
    if (res?.data) {
      dispatch({
        type: ActionTypes.UPDATE_USER_COUNTS_INFORMATION,
        value: {
          value: {
            ...res.data,
            totalFollowups:
              res.data.totalMessageCount.Low +
              res.data.totalMessageCount.Medium +
              res.data.totalMessageCount.Urgent,
            totalUnreadFollowups:
              res.data.unReadCount.Low +
              res.data.unReadCount.Medium +
              res.data.unReadCount.Urgent +
              res.data.unReadCount.NoResponseRequested +
              res.data.unReadCount.Resolved,
          },
          key: "followupCount",
        },
      });
    }
  };
  const isStaff: boolean =
    currentOrg?.roles?.length === 1 && currentOrg?.roles[0] === OrganizationRoleEnum.Staff;
  const isDoctor: boolean =
    currentOrg?.roles?.length === 1 && currentOrg?.roles[0] === OrganizationRoleEnum.Doctor;
  const isAdmin: boolean =
    currentOrg?.roles?.length === 1 && currentOrg?.roles[0] === OrganizationRoleEnum.Admin;

  const isPaidPlan: boolean = userDetailsState?.isPaidPlan ?? false;

  const isOrgOwner: boolean = currentOrg?.roles?.includes(OrganizationRoleEnum.OrganizationOwner);
  const canManageBilling: boolean = isOrgOwner;
  const canEditOfficeMember: boolean = !isStaff;
  const canEditPatient: boolean = !isAdmin && isPaidPlan;
  const canEditCase: boolean = !isAdmin && isPaidPlan;
  return {
    userDetails: userDetailsState,
    isAdmin,
    isStaff,
    isDoctor,
    canManageBilling,
    canEditCase,
    canEditOfficeMember,
    canEditPatient,
    updateUserCounts,
    userDetailsCount,
    userLicenseRemaining: Math.max(
      (currentSubscription?.subscriptionDetails?.totalLicenseCount ?? 0) -
      (currentSubscription?.activeOrgUsersCount ?? 0),
      0
    ),
    isUnlimitedPlan:
      currentSubscription?.subscriptionDetails?.planType === PlanType.UNLIMITED,
    isPaidPlan,
    currentSubscription: userDetailsState?.currentSubscription,
    isOrgOwner,
    personalInformation: userDetailsState?.personalInformation,
    userId: userDetailsState?._id,
    organizationId: userDetailsState?.organizationId,
  };
};
